import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component"; // ← התקן עם npm install react-infinite-scroll-component
import Styles from "../../Categories/Categories.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { Button } from "react-bootstrap";
import { DOMAIN } from "../../../../constants/envUrl";
import { useNavigate } from "react-router-dom";

import {
  UpdateProviderCategoriesListOptions,
  getProviderCategoriesListOptions,
  addMainCategoriesToProductCategory,
  gellAllCategoriesByProductCat,
  updateProductCategory,
  uploadFile,
  createProductCategory
} from "../../../../config/api";
import noPeopleImg from "../../../assets/Images/2.png";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Categories = ({ langConfig, langData }) => {
  const navigate = useNavigate();

  // ----------------------------------------------------
  // State variables
  // ----------------------------------------------------
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const [allCatByProduct, setAllCatByProduct] = useState([]);

  // עריכת שדות
  const [editingElement, setEditingElement] = useState(null);
  const [editableValue, setEditableValue] = useState("");

  // הוספת קטגוריה חדשה
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [newProductCat, setNewProductCat] = useState({
    name: "",
    imageLink: "",
    iconLink: "",
    description: ""
  });

  // ----------------------------------------------------
  // Infinite scroll states
  // ----------------------------------------------------
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true); // האם יש עוד נתונים להציג

  // ----------------------------------------------------
  // פונקציות API וכלים
  // ----------------------------------------------------

  // טעינת העמוד (pageIndex) מהשרת (עד 15 רשומות)
  const fetchAllCatByProductPage = async (currentPage) => {
    try {
      setCategoriesLoading(true);


      const data = { pageIndex: pageSize, pageSize: pageIndex };

      const response = await
      axios.post(gellAllCategoriesByProductCat(), data, {
        headers: { "Content-Type": "application/json" },
      });
      

      

      
      if (response.status === 200) {
        const data = response.data.data; // בהנחה שהשרת מחזיר מערך של רשומות

        // אם החזיר מערך ריק, כנראה אין עוד
        if (!data || data.length === 0) {
          setHasMore(false);
        } else {
          // מוסיפים (spread) את הרשומות החדשות למצב הקיים
          setAllCatByProduct((prev) => [...prev, ...data]);
          // אם בפחות מ-15, כנראה אין עוד
          if (data.length < pageSize) {
            setHasMore(false);
          }
        }
      }
    } catch (error) {
      console.error("fetchAllCatByProduct => Error:", error);
      setHasMore(false);
    } finally {
      setCategoriesLoading(false);
    }
  };

  // פונקציית "טעינת עוד" עבור InfiniteScroll
  const loadMoreData = () => {
    const nextPage = pageIndex + 1;
    setPageIndex(nextPage);
    fetchAllCatByProductPage(nextPage);
  };

  // פונקציה שמופעלת בעת יצירת קטגוריה חדשה
  // (לא "עמוד" חדש, אלא קטגוריה חדשה שנשמרת ב-DB)
  const createNewProductCat = async () => {
    try {
      const response = await axios.post(createProductCategory(), newProductCat);
      setIsAddCategory(false);
      console.log("createNewProductCat", response.data);

      // לאחר הוספה, מומלץ לרענן את הרשימה מהעמוד הראשון
      setAllCatByProduct([]);
      setPageIndex(0);
      setHasMore(true);
      fetchAllCatByProductPage(0);

      // איפוס השדות
      setNewProductCat({
        name: "",
        imageLink: "",
        iconLink: "",
        description: ""
      });
    } catch (err) {
      console.error("createNewProductCat error:", err);
    }
  };

  // עדכון ערכי הקטגוריה החדשה (name, description וכו')
  const formNewProductCat = async (key, event) => {
    if (key === "imageLink" || key === "iconLink") {
      const file = event.target.files[0];
      try {
        const formData = new FormData();
        formData.append("image", file);

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        if (response.status !== 200) return;
        const data = response.data;

        // שמירת ה-link שהוחזר
        setNewProductCat((prevProdCat) => ({
          ...prevProdCat,
          [key]: `/server-images/${data}`
        }));
        console.log("formNewProductCat: ", data);
      } catch (err) {
        console.log(err);
      }
    } else {
      setNewProductCat((prevProdCat) => ({
        ...prevProdCat,
        [key]: event.target.value
      }));
    }
  };

  // העלאת תמונה לעריכת קטגוריה קיימת
  const uploadImage = async (event, obj, key) => {
    const file = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await axios.post(uploadFile(), formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response.status !== 200) return;

      const data = response.data;
      const imageUrl = `/server-images/${data}`;
      setEditableValue(imageUrl);

      const { description, categoryImage, categoryIcon, niceName, id } = obj;

      // בונים אובייקט חדש לעדכון
      const updatedObject = {
        id: id,
        name: niceName,
        imageLink: key === "imageLink" ? imageUrl : categoryImage,
        iconLink: key === "iconLink" ? imageUrl : categoryIcon,
        description: description
      };

      // שליחת בקשה לעדכון
      const responseUpdate = await axios.post(updateProductCategory(), updatedObject);
      if (responseUpdate.data.result) {
        setEditingElement(null);
        setEditableValue("");
        // רענון
        setAllCatByProduct([]);
        setPageIndex(0);
        setHasMore(true);
        fetchAllCatByProductPage(0);
      }

      return data;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  // עריכת שדות טקסט בקטגוריה קיימת
  const updateProductCat = async (obj, key) => {
    const { description, categoryImage, categoryIcon, niceName, id } = obj;

    // אובייקט לעדכון
    const updatedObject = {
      id: id,
      name: key === "name" ? editableValue : niceName,
      imageLink: key === "imageLink" ? editableValue : categoryImage,
      iconLink: key === "iconLink" ? editableValue : categoryIcon,
      description: key === "description" ? editableValue : description
    };

    console.log("updateProductCat => ", updatedObject);
    const response = await axios.post(updateProductCategory(), updatedObject);
    if (response.data.result) {
      setEditingElement(null);
      setEditableValue("");

      // רענון
      setAllCatByProduct([]);
      setPageIndex(0);
      setHasMore(true);
      fetchAllCatByProductPage(0);
    }
  };

  // טיפול בסימון קטגוריות ראשיות (Checkbox) עבור כל קטגוריית Product
  const handleCheckOption = async (event, indexMain, indexInner, mainCatId, productCatId) => {
    console.log(
      "handleCheckOption => ",
      event.target.checked,
      indexMain,
      indexInner,
      mainCatId,
      productCatId
    );

    // 1) נעדכן מקומית ב-STATE את הסימון
    const updatedCatByProduct = [...allCatByProduct];
    updatedCatByProduct[indexMain] = {
      ...updatedCatByProduct[indexMain],
      mainCategoryList: updatedCatByProduct[indexMain].mainCategoryList.map(
        (item, idx) => {
          if (idx === indexInner) {
            return { ...item, checked: event.target.checked };
          }
          return item;
        }
      )
    };
    setAllCatByProduct(updatedCatByProduct);

    // 2) אוספים את כל ה-ID המסומנים
    const checkedCategoryIds = updatedCatByProduct[indexMain].mainCategoryList
      .filter((cat) => cat.checked)
      .map((cat) => cat.category.id);

    // 3) קריאה לשרת
    const params = {
      categoryProductId: productCatId,
      mainCategoriesList: checkedCategoryIds
    };

    try {
      const response = await axios.post(addMainCategoriesToProductCategory(), params, {
        headers: { "Content-Type": "application/json" }
      });
      console.log(response.data);
      if (response.data.result) {
        // במידת הצורך רענון: (תלוי בך, לא תמיד חובה)
        // setAllCatByProduct([]);
        // setPageIndex(0);
        // setHasMore(true);
        // fetchAllCatByProductPage(0);
      }
    } catch (error) {
      console.error("Error: addMainCategoriesToProductCategory failed", error);
    }
  };

  // טעינת רשימת קטגוריות כללית (לא בהכרח קשור לרשימת ה-ProductCat)
  const fetchCategoriesList = async () => {
    try {
      setCategoriesLoading(true);
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesList(data.data);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  // עדכון רשימת קטגוריות לספק (לא תמיד בשימוש)
  const UpdateCategoriesListOptions = async () => {
    try {
      setCategoriesLoading(true);
      await axios.post(UpdateProviderCategoriesListOptions(), categoriesList);
      setCategoriesLoading(false);
      await fetchCategoriesList();
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  // כפתור "הוסף קטגוריה משנית" -> מעבר לעמוד עריכה/הוספה
  const addCategory = () => {
    navigate("/add-product-category");
  };

  // useEffect ראשוני: נטען את עמוד 0
  useEffect(() => {
    setAllCatByProduct([]);
    setPageIndex(0);
    setHasMore(true);
    fetchAllCatByProductPage(0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={Styles.containerUser}>
      {/* כפתור להוספת קטגוריה חדשה */}
      <div style={{ display: "flex", justifyContent: "left" }}>
        <button onClick={addCategory} className={Styles.btnStyle}>
          הוסף קטגוריה משנית
        </button>
      </div>

      <div className="card mt-5">
        <div className="card-header">
          <h2 className="card-title">קטגוריות משניות</h2>
        </div>

        {/* ➊ עוטפים את תוכן הטבלה ב-InfiniteScroll */}
        <InfiniteScroll
          dataLength={allCatByProduct.length} // כמות הפריטים שיש לנו
          next={loadMoreData}                // פונקציה לטעינת עמוד נוסף
          hasMore={hasMore}                  // האם יש עוד נתונים?
          loader={<h4>טוען...</h4>}         // יוצג בזמן הטעינה
          endMessage={<p>אין עוד תוצאות</p>} // כשמגיעים לסוף
          scrollThreshold={0.9}             // 0.9 => טען כשאנחנו ב-90% למטה
          style={{ overflow: "visible" }}    // כדי שהגלילה תהיה על הדף עצמו
        >
          <div className="card-body">
            <div className={Styles.tableResponsive}>
              <table className={`${Styles.table} ${Styles.tableStriped}`}>
                <thead>
                  <tr>
                    <th className={Styles.horizantal}>
                      {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.mainCat}
                    </th>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catName}
                    </th>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catDesc}
                    </th>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catIcon}
                    </th>
                    <th>
                      {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.catImage}
                    </th>
                    <th></th>
                  </tr>
                </thead>

                {!categoriesLoading ? (
                  <tbody>
                    {allCatByProduct && allCatByProduct.length > 0 ? (
                      allCatByProduct.map((itemList, index) => (
                        <tr key={index}>
                          {/* בחירת קטגוריות ראשיות (Checkboxes) */}
                          <td>
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id={`demo-multiple-checkbox-label-${index}`}>
                                קטגוריות
                              </InputLabel>
                              <Select
                                labelId={`demo-multiple-checkbox-label-${index}`}
                                id={`demo-multiple-checkbox-${index}`}
                                multiple
                                value={allCatByProduct}
                                // אתה לא משנה כאן באמצעות onChange, אלא משתמש ב-handleCheckOption ישירות ב-Checkbox
                                input={<OutlinedInput label="Categories" />}
                                renderValue={(selected) => {
                                  // כאן קצת טריקי בגלל שאתה שומר את כל הרשימה ב-value
                                  // אפשר להסתפק בתצוגה המבוססת על המידע ב-itemList.mainCategoryList
                                  return itemList.mainCategoryList
                                    ?.filter((x) => x.checked)
                                    .map((cat) => cat.category.categoryAdminName)
                                    .join(", ");
                                }}
                                MenuProps={MenuProps}
                              >
                                {itemList.mainCategoryList?.map((option, indexInner) => (
                                  <MenuItem
                                    key={indexInner}
                                    value={option.category?.categoryName}
                                  >
                                    <Checkbox
                                      checked={option.checked}
                                      onChange={(e) =>
                                        handleCheckOption(
                                          e,
                                          index,
                                          indexInner,
                                          option.category?.id,
                                          itemList.productCategory?.id
                                        )
                                      }
                                    />
                                    <ListItemText
                                      primary={option.category?.categoryAdminName}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </td>

                          {/* שם קטגוריה (עריכה טקסט) */}
                          <td>
                            {editingElement === `${index}0` ? (
                              <>
                                <input
                                  onChange={(e) => setEditableValue(e.target.value)}
                                  defaultValue={itemList.productCategory?.niceName}
                                  type="text"
                                />
                                <button
                                  onClick={() =>
                                    updateProductCat(
                                      itemList.productCategory,
                                      "name"
                                    )
                                  }
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <span>{itemList.productCategory?.niceName}</span>
                            )}
                          </td>

                          {/* תיאור קטגוריה (עריכה טקסט) */}
                          <td>
                            {editingElement === `${index}1` ? (
                              <>
                                <input
                                  onChange={(e) => setEditableValue(e.target.value)}
                                  defaultValue={itemList.productCategory?.description}
                                  type="text"
                                />
                                <button
                                  onClick={() =>
                                    updateProductCat(
                                      itemList.productCategory,
                                      "description"
                                    )
                                  }
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <span>{itemList.productCategory?.description}</span>
                            )}
                          </td>

                          {/* אייקון (תמונה) */}
                          <td>
                            <img
                              className={Styles.tableImage}
                              src={`${DOMAIN}${itemList.productCategory?.categoryIcon}`}
                              alt=""
                            />
                            <label>
                              <input
                                style={{
                                  width: 0,
                                  position: "absolute",
                                  left: "100%"
                                }}
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  uploadImage(e, itemList.productCategory, "iconLink")
                                }
                              />
                            </label>
                          </td>

                          {/* תמונה (תמונה) */}
                          <td>
                            <img
                              className={`${Styles.tableImage} img-people`}
                              src={`${DOMAIN}${itemList.productCategory?.categoryImage}`}
                              alt=""
                            />
                            <img
                              className={`${Styles.tableImage} img-no-people`}
                              src={noPeopleImg}
                              alt=""
                            />
                            <label>
                              <input
                                style={{
                                  width: 0,
                                  position: "absolute",
                                  left: "100%"
                                }}
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  uploadImage(e, itemList.productCategory, "imageLink")
                                }
                              />
                            </label>
                          </td>

                          {/* מעבר לעריכה מלאה בעמוד נפרד */}
                          <td>
                            <Button
                              onClick={() =>
                                navigate(`/edit-product-category/${itemList.productCategory?.id}`)
                              }
                            >
                              עריכה
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center py-5">
                          אין תוצאות
                        </td>
                      </tr>
                    )}

                    {/* אם אתה עדיין משתמש באפשרות להוסיף בשורה "inline": */}
                    {isAddCategory && (
                      <tr>
                        <td></td>
                        <td>
                          <input
                            onChange={(e) => formNewProductCat("name", e)}
                            type="text"
                            placeholder="שם הקטגוריה"
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) => formNewProductCat("description", e)}
                            type="text"
                            placeholder="תיאור הקטגוריה"
                          />
                        </td>
                        <td>
                          {newProductCat.iconLink ? (
                            <img
                              className={Styles.tableImage}
                              src={`${DOMAIN}${newProductCat.iconLink}`}
                              alt=""
                            />
                          ) : (
                            <label>
                              <input
                                style={{
                                  width: 0,
                                  position: "absolute",
                                  left: "100%"
                                }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => formNewProductCat("iconLink", e)}
                              />
                              <span className={Styles.btnStyle}>
                                {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.uploadIcon}
                              </span>
                            </label>
                          )}
                        </td>
                        <td>
                          {newProductCat.imageLink ? (
                            <>
                              <img
                                className={`${Styles.tableImage} img-people`}
                                src={`${DOMAIN}${newProductCat.imageLink}`}
                                alt=""
                              />
                              <img
                                className={`${Styles.tableImage} img-no-people`}
                                src={noPeopleImg}
                                alt=""
                              />
                            </>
                          ) : (
                            <label>
                              <input
                                style={{
                                  width: 0,
                                  position: "absolute",
                                  left: "100%"
                                }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => formNewProductCat("imageLink", e)}
                              />
                              <span className={Styles.btnStyle}>
                                {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.uploadImage}
                              </span>
                            </label>
                          )}
                        </td>
                        <td>
                          <button onClick={createNewProductCat} className={Styles.btnStyle}>
                            {langData[langConfig.userType][langConfig.lang].tab2.productCategories.table.saveCategory}
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" className="text-center" style={{ height: "200px" }}>
                        <div className="spinner-border text-primary" role="status"></div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Categories;
